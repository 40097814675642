<template>
  <div class="d-flex ml-5 align-center">
    <div>
      <span class="font-weight-regular body-2">
        {{ count }}
      </span>     
    </div>
    <v-container class="d-flex justify-space-between">
      <div v-for="item in items" :key="item.id" :class="item.class">
        <v-btn
          text
          small
          class="font-weight-regular"
          @click="changeSort(item.id)"
        >
        <span v-if="pathMatch === 'bibliography' && item.id==='name'">
          {{ item.title[1] }}
        </span>
        <span v-else>
          {{ item.title[0] }}
          </span>          
          <v-icon v-if="sort[item.sort] === 'asc'" class="ml-1" x-small>
            mdi-arrow-up
          </v-icon>
          <v-icon v-else-if="sort[item.sort] === 'dsc'" class="ml-1" x-small>
            mdi-arrow-down
          </v-icon>
        </v-btn>
      </div>
    </v-container>
  </div>    
</template>

<script>
export default {
  props: ["pathMatch","items","count"],
  data() {
    return {
      sort: ["none", "asc", "dsc"],
    };
  },
  methods: {
    changeSort(target) {
      return this.$emit("change-sort", target);
    },
  },
};
</script>