export default {
    labelSort(item, filter) {
        switch (item.sort) {
            case 1:
                filter.sort((a, b) => {
                    if (a[item.value[0]][0] < b[item.value[0]][0]) return -1
                    if (a[item.value[0]][0] > b[item.value[0]][0]) return 1;
                    return 0
                })
                break;
            case 2:
                filter.sort((a, b) => {
                    if (a[item.value[0]][0] < b[item.value[0]][0]) return 1
                    if (a[item.value[0]][0] > b[item.value[0]][0]) return -1;
                    return 0
                })
                break;
            default:
                break;
        }
        return filter
    },
    preflabelSort(item, filter) {
        switch (item.sort) {
            case 1:
                filter.sort((a, b) => {
                    if (a[item.value[1]] < b[item.value[1]]) return -1
                    if (a[item.value[1]] > b[item.value[1]]) return 1;
                    return 0
                })
                break;
            case 2:
                filter.sort((a, b) => {
                    if (a[item.value[1]] < b[item.value[1]]) return 1
                    if (a[item.value[1]] > b[item.value[1]]) return -1;
                    return 0
                })
                break;
            default:
                break;
        }
        return filter
    },
    valueSort(item, filter) {
        switch (item.sort) {
            case 1:
                filter.sort((a, b) => a[item.value[0]] - b[item.value[0]])
                break;
            case 2:
                filter.sort((a, b) => b[item.value[0]] - a[item.value[0]])
                break;
            default:
                break;
        }
        
        return filter
    }
}