<template>
  <v-card elevation="2">
    <v-card-title>
      {{ $t(title) }}
    </v-card-title>
    <v-card-subtitle class="pb-6" v-html="$t(description)">      
    </v-card-subtitle>
    <section>
      <v-container class="mb-2">
        <v-row>
          <v-col>
            <v-row>
              <v-col class="pb-0 mt-1" :cols="cols">
                <v-text-field
                  v-model="search"
                  label="Cerca"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col :cols="cols" class="">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{ $t("collection.advance_search.structure.title") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="filter-content">
                      <Structure :filtersPath="path" @changed-path="savePath" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" class="">
                <RangeSlider
                  @set-date="saveRangeState"
                  :date="date"
                  :selAllDate="selAllDate"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="isRoutePlaces" cols="6" class="mapMinHeight">
            <BubbleMap :places="filteredIndex"/>
          </v-col>
        </v-row>
      </v-container>
      <SelectedFilters
        :localFilters="searchFilter"
        :queryFilters="indexFilters"
      />
      <v-divider></v-divider>
      <SortBar
        :pathMatch="pathMatch"
        :count="filteredIndex.length"
        :items="sortItems"
        @change-sort="updateSort"
      />
      <linear-loading v-if="isLoading" :isLoading="isLoading"></linear-loading>
      <v-expansion-panels
        v-else-if="filteredIndex.length > 0"
        v-model="panel"
        multiple
        focusable
      >
        <v-expansion-panel v-for="i in filteredIndex" :key="i.URI">
          <!-- <v-lazy            
            v-model="i.isActive"
            :options="{
              threshold: 0.2,
            }"
            min-height="60"            
            transition="fade-transition"
          > -->
            <v-expansion-panel-header v-if="pathMatch === 'people'">
              <div>
                <v-icon>
                  {{
                    indexes.filter((index) => index.id === pathMatch)[0].icon
                  }}
                </v-icon>
                {{ i.PREFLABEL }}
              </div>
              <div class="d-flex mr-2">
                <v-spacer></v-spacer>
                <v-chip color="primary">
                  {{ i.MENTIONSCOUNTER }}
                </v-chip>
              </div>
            </v-expansion-panel-header>
          <v-expansion-panel-header v-else>
            <div>
              <v-icon>
                {{ indexes.filter((index) => index.id === pathMatch)[0].icon }}
              </v-icon>
              {{ i.LABEL[0] }}
            </div>
            <div class="d-flex mr-2">
              <v-spacer></v-spacer>
              <v-chip color="primary">
                {{ i.MENTIONSCOUNTER }}
              </v-chip>
            </div>
          </v-expansion-panel-header>
          <!-- </v-lazy> -->
          <v-expansion-panel-content color="primary" class="pt-2">
            <v-container>
              <v-card
                v-if="(i.ALTLABEL && i.ALTLABEL.length > 0) || i.AUTHORITY"
              >
                <v-card-subtitle>
                  <div v-if="i.ALTLABEL && i.ALTLABEL.length > 0">
                    <strong>
                      {{ $t("collection.indexes.navigation.forms") }}:
                    </strong>
                    <span
                      v-for="(altLabel, key) in i.ALTLABEL"
                      :key="altLabel"
                    >
                      {{ altLabel }}
                      <strong v-if="key !== i.ALTLABEL.length - 1">/</strong>
                    </span>
                  </div>
                  <div v-if="i.AUTHORITY">
                    <strong>
                      {{ $t("collection.indexes.navigation.authority") }}:
                    </strong>
                    <a :href="i.AUTHORITY" target="_blank">{{
                      i.AUTHORITY
                    }}</a>
                  </div>
                </v-card-subtitle>
              </v-card>
              <v-card
                class="mt-3 pb-2"
                v-for="expression in i.EXPRESSIONS"
                :key="expression.value"
              >
                <div class="d-flex align-center pb-2">
                  <v-card-title class="text-break">
                    {{
                      expression.title
                        .replace("Espressione dell'opera", "")
                        .replace(/"/g, "")
                    }}
                  </v-card-title>
                  <v-btn
                    @click="link(expression.value, i.URI)"
                    class="ml-auto mr-4"
                    color="primary"
                    small
                  >
                      {{ $t('basics.goToWork') }}
                  </v-btn>
                </div>
                <v-sheet class="mb-2 overflow" max-height="85">
                  <v-card-text class="py-0">
                    {{ expression.abstract }}
                  </v-card-text>
                </v-sheet>
              </v-card>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div v-else>
        <v-divider></v-divider>
        <v-card-title> Non ci sono elementi </v-card-title>
      </div>
      <div class="text-center">
        <!-- <v-pagination
      v-model="page"
      :length="6"
    ></v-pagination> -->
      </div>
    </section>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import sort from "@/sort.js";
import RangeSlider from "@/components/search/filters/RangeSlider";
import Structure from "@/components/search/filters/Structure";
import SortBar from "@/components/sort/SortBar";
import SelectedFilters from "@/components/search/SelectedFilters";
import BubbleMap from "@/components/maps/BubbleMap";
//import L from 'leaflet';

export default {
  components: {
    RangeSlider,
    Structure,
    SortBar,
    SelectedFilters,
    BubbleMap,
  },
  data() {
    return {
      page: 1,
      panel: [],
      pathMatch: "",
      indexes: [
        {
          id: "people",
          icon: "mdi-account",
          title: "collection.indexes.people.title",
          description: "collection.indexes.people.description",
        },
        {
          id: "places",
          icon: "mdi-map-marker",
          title: "collection.indexes.places.title",
          description: "collection.indexes.places.description",
        },
        {
          id: "organizations",
          icon: "mdi-domain",
          title: "collection.indexes.org.title",
          description: "collection.indexes.org.description",
        },
        {
          id: "bibliography",
          icon: "mdi-bookmark",
          title: "collection.indexes.bib.title",
          description: "collection.indexes.bib.description",
        },
      ],
      isLoading: false,
      isRoutePlaces: false,
      cols: 12,
    };
  },
  computed: {
    ...mapGetters({
      index: "indexes/index",
      lastIndex: "indexFilters/lastIndex",
      sortItems: "indexSorting/sortItems",
    }),

    search: {
      get() {
        return this.$store.getters["indexFilters/search"];
      },
      async set(value) {
        return await this.$store.dispatch("indexFilters/updateSearch", value);
      },
    },
    date: {
      get() {
        return this.$store.getters["indexFilters/date"];
      },
      async set(value) {
        return await this.$store.dispatch("indexFilters/updateDate", value);
      },
    },
    selAllDate: {
      get() {
        return this.$store.getters["indexFilters/selAllDate"];
      },
      async set(value) {
        return await this.$store.dispatch(
          "indexFilters/updateSelAllDate",
          value
        );
      },
    },
    path: {
      get() {
        return this.$store.getters["indexFilters/path"];
      },
      async set(value) {
        return await this.$store.dispatch("indexFilters/updatePath", value);
      },
    },
    indexFilters() {
      return {
        path: this.path,
        date: this.date,
        selAllDate: this.selAllDate,
      };
    },
    searchFilter() {
      return {        
        title: this.search,
      };
    },
    filteredIndex() {
      let filter;
      if (this.pathMatch !== "people") {
        filter = this.index.filter((index) => {
          if (
            index.LABEL[0]
              .toUpperCase()
              .includes(this.search.toUpperCase().trim())
          )
            return true;
        });
      } else {
        filter = this.index.filter((index) => {
          if (
            index.PREFLABEL &&
            (index.PREFLABEL
              .toUpperCase()
              .includes(this.search.toUpperCase().trim()) ||
              index.LABEL[0]
                .toUpperCase()
                .includes(this.search.toUpperCase().trim()))
          )
            return true;
        });
      }
      
      return this.sortIndex(filter);
    },
    title() {
      return this.indexes.filter((index) => index.id === this.pathMatch)[0]
        .title;
    },
    description() {
      return this.indexes.filter((index) => index.id === this.pathMatch)[0]
        .description;
    },
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_title:
          this.pathMatch.charAt(0).toUpperCase() + this.pathMatch.slice(1),
        page_path: "/edition/browse/indexes/" + this.pathMatch,
      });
    },
    closeAllPanel() {
      return (this.panel = []);
    },
    sortIndex(filter) {
      for (let item of this.sortItems) {
        if (this.pathMatch === "people" && item.value.length > 1) {          
          filter = sort.preflabelSort(item, filter);
        } else {
          if (item.value.length > 1) {
            filter = sort.labelSort(item, filter);
          }
          filter = sort.valueSort(item, filter);
        }
      }
      return filter;
    },
    saveRangeState(payload) {
      this.date = payload.date;
      this.selAllDate = payload.selDate;

      //return this.loadIndexes();
    },
    savePath(payload) {
      this.path = payload;

      //return this.loadIndexes();
    },
    async updateSort(payload) {
      return await this.$store.dispatch("indexSorting/updateSort", payload);
    },
    async loadIndexes() {
      this.isLoading = true;
      try {
        const filters = this.$store.getters["indexFilters/filters"];
        await this.$store.dispatch("indexes/loadIndexes", {
          indexName: this.pathMatch,
          filters,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong";
      }
      this.isLoading = false;
    },
    async link(uri, entity) {
      // save new browsing position
      await this.$store.dispatch("changeBrowisingState", this.$route.path);

      const entityID = entity.split("/");
      const split = uri.split("/");
      return this.$router.push({
        path: `/edition/browse/work/${split[split.length - 2]}`,
        query: {
          entity: entityID[entityID.length - 1],
        },
      }); //_${this.work._id}
    },
    checkRoutePlaces(){
      if(this.$route.params.pathMatch == "places"){
      this.isRoutePlaces= true;
      this.cols=12
    }else{
      this.isRoutePlaces = false;
      this.cols=6
    }
    }
  },
  async created() {
    this.checkRoutePlaces();
    this.pathMatch = this.$route.params.pathMatch;
    await this.loadIndexes();
  },
  watch: {
    async $route() {
      this.checkRoutePlaces();
      this.pathMatch = this.$route.params.pathMatch;
      this.closeAllPanel();
      //await this.$store.dispatch('indexFilters/resetFilters');
      await this.loadIndexes();
      this.track();
    },
    async indexFilters() {
      return await this.loadIndexes();
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow {
  overflow: auto;
}

.disable-event {
  pointer-events: none;
  opacity: 0.5;
}

.filter-content {
  height: 150px;
  overflow-y: scroll;
}

</style>