<template>
  <div>
    <l-map
      :zoom="zoom"
      :minZoom="minZoom"
      :maxZoom="maxZoom"
      :center="center"
      :options="{worldCopyJump:true}"
      ref="mymap"
      v-on:update:zoom="zoomUpdated($event)"
      class="mapDimensions"
    >
      <!-- Continenti -->
      <l-circle
        v-for="(l, index) in locations"
        :key="l.LABEL + index"
        :lat-lng="[l.POSITION.LAT, l.POSITION.LONG]"
        :radius="calcolateRadius(parseInt(l.MENTIONSCOUNTER))"
        :fillColor="colorHASH"
        :stroke="false"
        :fillOpacity="0.7"
        @click="filterClickMap(l)"
      >
        <l-tooltip>
          <p class="pb-0 mb-0">Luogo: {{String(l.LABEL)}}</p>
          <p class="pt-1 mt-0">Menzioni: {{String(l.MENTIONSCOUNTER)}}</p>
        </l-tooltip>
      </l-circle>

      <l-tile-layer :url="url" :attribution="attribution"/>

      <l-control>
        <v-btn
        @click="clearFilters()"
        elevation="2"
        small
        >
          Reset
        </v-btn>
      </l-control>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LCircle, LTooltip, LControl } from "vue2-leaflet";
import { latLng } from "leaflet";
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

export default {
  name: "PopupGeometryTest",
  props: {
    places: Array,
  },
  components: {
    LMap,
    LTileLayer,
    LCircle,
    LTooltip,
    LControl 
  },
  data() {
    return {
      zoom: 1.5,
      minZoom: 1.5,
      maxZoom: 8,
      center: [0, -10],

      circle: {
        center: latLng(47.41322, -1.0482),
        radius: 4500,
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  computed: {
    locations() {
      if(this.zoom < 3){
        return this.continents;
      }else if(this.zoom < 5 && this.zoom >= 3) {
        return this.nations;
      }else {
        return this.cities;
      }
    },
    colorHASH() {
      if(this.zoom < 3){
        return "#8f00ff";
      }else if(this.zoom < 5 && this.zoom >= 3) {
        return "#2271b3";
      }else {
        return "#ff7514";
      }
    },
    continents() {
      if (this.places.length > 0) {
        return this.places.filter((place) => {
          if (place.POSITION) {
            if (
              place.POSITION.FEATURECLASS[
                place.POSITION.FEATURECLASS.length - 1
              ] == "L"
            )
              return true;
          }
        });
      }
      return [];
    },
    nations() {
      if (this.places.length > 0) {
        return this.places.filter((place) => {
          if (place.POSITION) {
            if (
              place.POSITION.FEATURECLASS[
                place.POSITION.FEATURECLASS.length - 1
              ] == "A"
            )
              return true;
          }
        });
      }
      return [];
    },
    cities() {
      if (this.places.length > 0) {
        return this.places.filter((place) => {
          if (place.POSITION) {
            if (
              place.POSITION.FEATURECLASS[
                place.POSITION.FEATURECLASS.length - 1
              ] == "P"
            )
              return true;
          }
        });
      }
      return [];
    },
  },
  methods: {

    async filterClickMap(location){
      return await this.$store.dispatch("indexFilters/updateSearch", location.LABEL[0]);
    },

    async clearFilters(){
      if(this.$route.path.includes('indexes')) await this.$store.dispatch('indexFilters/resetFilters')
      else {
        await this.$store.dispatch("removeAllFilter");
      }
    },

    zoomUpdated(zoom) {
      this.zoom = zoom;
      console.log(zoom);
    },

    calcolateRadius(mentions) {
      if(this.zoom < 3){
        if(mentions < 50){
          return 200000 + (mentions * 1000);
        }else if(mentions < 100){
          return 300000 + (mentions * 1000);
        }else{
          return 500000
        }
      }else if(this.zoom < 5 && this.zoom >= 3) {
        if(mentions < 50){
          return 75000 + (mentions * 1000);
        }else if(mentions < 100){
          return 100000 + (mentions * 1000);
        }else{
          return 200000
        }
      }else {
        if(mentions < 50){
          return 10000 + (mentions * 100);
        }else if(mentions < 100){
          return 30000 + (mentions * 100);
        }else{
          return 60000
        }
      }
      
      
    },
  },
  mounted() {
    const map = this.$refs.mymap.mapObject;
    map.addControl(new window.L.Control.Fullscreen());
  }
};
</script>

<style scoped>
.mapDimensions {
  height: 100%;
  width: 100%;
  min-height: 500px;
  z-index: 0;
}
</style>
